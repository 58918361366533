import React, { Component } from 'react';
import classNames from 'classnames';
import { Modal, Spin } from "antd-min";
import { fmtMsg, DateHelper, EventType, SchoolEventType, CampusEventType, ChangeLogType, EventInfo } from '@app/util';
import { EventModalContentProps, ChangeEventModel, ChangeEventPropsModel } from '@app/service/cims';
import { SchoolLocale } from '@app/locales/localeid';
import { FormItemsLayout } from 'gl-commonui';
import { FormItem, FormItemLayout4ClassName, EventModalTitleIds, EventModalHideClassName, EventModalOptinalStyle, FormItemLayout4Footer, FormItemLayout4AuditChangeTitle, SchoolEventModalOptinalStyle, CampusEventModalOptinalStyle, SchoolEventModalTitleIds, CampusEventModalTitleIds, canApproveDeny4ChangeType } from './cims-consts';
import { ClassInfo } from './class-info';
import { LicenseUpdated } from './license-updated';
import { UnitPlan } from './unit-plan';
import { MandatoryAttributeComparer } from './mandatory-attribute-comparer';
import { SchoolMandatoryAttributeComparer } from './school-mandatory-attribute-comparer';
import { CampusMandatoryAttributeComparer } from './campus-mandatory-attribute-comparer';
import { StudentMovement } from './student-movement';
import { StudentRemoved } from './student-removed';
import { CampusInfo } from './campus-info';
import { PromoteClassComparer } from './promote-class-comparer';
import { MaterialInfo } from './material-info';
import { MovePromoteStudentsCimsInfo } from './move-promote-students-cims-info';
import { EventModalFooter, getApproveDenyModalContents, getEventModalOptinalStyle as getApproveDenyEventModalOptinalStyle } from './approve-deny-modal';
import { maxBy } from 'lodash';

export const SchoolEventModalContents: Map<EventInfo, React.FC<EventModalContentProps<ChangeEventModel>>> = new Map([
    [EventInfo.PendingChangesChangeSchoolMandatoryData, SchoolMandatoryAttributeComparer],
    [EventInfo.PendingChangesMovePromoteStudents, MovePromoteStudentsCimsInfo]
]);

export const CampusEventModalContents: Map<EventInfo, React.FC<EventModalContentProps<ChangeEventModel>>> = new Map([
    [EventInfo.PendingChangesAddCampus, CampusInfo],
    // [EventInfo.PendingChangesDisableCampus, CampusInfo],
    [EventInfo.PendingChangesChangeCampusMandatoryData, CampusMandatoryAttributeComparer],
    [EventInfo.PendingChangesMaterialOrder, MaterialInfo]
    // [EventInfo.PendingChangesEnableCampus, CampusInfo],
]);

export const EventModalContents: Map<EventInfo, React.FC<EventModalContentProps<ChangeEventModel>>> = new Map([
    [EventInfo.PendingChangesAddClass, ClassInfo],
    [EventInfo.PendingChangesPromoteClass, PromoteClassComparer],
    [EventInfo.PendingChangesChangeLicense, LicenseUpdated],
    // [EventInfo.PendingChangesDisableClass, ClassInfo],
    [EventInfo.PendingChangesChangeClassUnitPlan, UnitPlan],
    [EventInfo.PendingChangesChangeClassMandatoryData, MandatoryAttributeComparer],
    [EventInfo.PendingChangesMoveStudent, StudentMovement],
    [EventInfo.PendingChangesRemoveStudent, StudentRemoved],
    // [EventInfo.PendingChangesEnableClass, ClassInfo],
    [EventInfo.PendingChangesMoveUnregisteredStudent, StudentMovement],
]);

function getEventModalContents(target: ChangeLogType, type) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolEventModalContents.has(type) ? SchoolEventModalContents.get(type) : null;
        case ChangeLogType.Campus:
            return CampusEventModalContents.has(type) ? CampusEventModalContents.get(type) : null;
        case ChangeLogType.SchoolClass:
        default:
            return EventModalContents.has(type) ? EventModalContents.get(type) : null;
    }
}

function getEventModalOptinalStyle(target: ChangeLogType, type) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolEventModalOptinalStyle.has(type) ? SchoolEventModalOptinalStyle.get(type) : {};
        case ChangeLogType.Campus:
            return CampusEventModalOptinalStyle.has(type) ? CampusEventModalOptinalStyle.get(type) : {};
        case ChangeLogType.SchoolClass:
        default:
            return EventModalOptinalStyle.has(type) ? EventModalOptinalStyle.get(type) : {};
    }
}

function getEventModalTitleIds(target: ChangeLogType, type) {
    switch (target) {
        case ChangeLogType.School:
            return SchoolEventModalTitleIds.has(type) ? SchoolEventModalTitleIds.get(type) : null;
        case ChangeLogType.Campus:
            return CampusEventModalTitleIds.has(type) ? CampusEventModalTitleIds.get(type) : null;
        case ChangeLogType.SchoolClass:
        default:
            return EventModalTitleIds.has(type) ? EventModalTitleIds.get(type) : null;
    }
}

function getSubTitle(target: ChangeLogType, type) {
    switch (target) {
        case ChangeLogType.School:
            return null;
        case ChangeLogType.Campus:
            return type == EventInfo.PendingChangesAddCampus ? null : type == EventInfo.PendingChangesMaterialOrder ? fmtMsg({ id: SchoolLocale.CIMSChangeEventCampusMaterialSubTitle }) : fmtMsg({ id: SchoolLocale.CIMSChangeEventCampusSubTitle });
        case ChangeLogType.SchoolClass:
        default:
            return fmtMsg({ id: SchoolLocale.CIMSChangeEventClassName });
    }
}
export const EventModal: React.FC<EventModalContentProps<ChangeEventModel>> = (props: EventModalContentProps<ChangeEventModel>) => {
    const { target, type, visible, loading, onCancel, item, schoolClassName, showResolvedEvent } = props;
    const campusMaterialModal = target == ChangeLogType.Campus && type === EventInfo.PendingChangesMaterialOrder as any;
    const displayHeader = target == ChangeLogType.SchoolClass && EventModalHideClassName.has(type) ? EventModalHideClassName.get(type) : campusMaterialModal ? false : true;
    const modalOptionalStyle = showResolvedEvent ? getEventModalOptinalStyle(target, type) : getApproveDenyEventModalOptinalStyle(target, type);
    const modalClassNames = {
        'change-event-modal': true,
        'align-items-right': true,
        'approve-deny-modal': true,
        ...modalOptionalStyle
    }
    const lastChangeItem = maxBy(
        item["changeData"] || item,
        ChangeEventPropsModel.createdDate
    );

    if (showResolvedEvent) {
        const ModalContent = getEventModalContents(target, type);
        return (
            <Modal
                title={<EventModalTitle {...props} />}
                footer={null}
                className={classNames(modalClassNames)}
                visible={visible}
                destroyOnClose={true}
                onCancel={onCancel}
            >
                <Spin spinning={loading} >
                    {displayHeader && <EventModalHeader {...props} />}
                    {ModalContent && <ModalContent {...props} />}
                </Spin>
            </Modal>
        )
    }

    const ModalContent = getApproveDenyModalContents(target, type);
    return (
        <Modal
            title={<EventModalTitle {...{ ...props, lastChangeItem }} />}
            footer={null}
            className={classNames(modalClassNames)}
            visible={visible}
            destroyOnClose={true}
            onCancel={onCancel}
        >
            <Spin spinning={loading} >
                {displayHeader && <EventModalHeader {...props} />}
                {ModalContent && <ModalContent {...props} changeType={type} />}
                {canApproveDeny4ChangeType(target, type) && (
                    <EventModalFooter {...{ ...props, changeType: type, lastChangeItem, schoolClassName }} />
                )}
            </Spin>
        </Modal>
    )
}

const EventModalTitle: React.FC<EventModalContentProps<ChangeEventModel>> = (props: EventModalContentProps<ChangeEventModel>) => {
    const { target, type, item, lastChangeItem } = props;
    const title = getEventModalTitleIds(target, type);
    const itemToUse = lastChangeItem ? lastChangeItem : item; // item is object in case of resolved changes
    return (itemToUse ? (
        <React.Fragment>
            <div className='change-event-modal-title'>
                <span>{fmtMsg({ id: title })}</span>
            </div>
            <div className='change-event-modal-subtitle'>
                <FormItem {...FormItemLayout4AuditChangeTitle} label={fmtMsg({ id: SchoolLocale.CIMSChangeEventModifyDate })}>
                    <span className="ant-form-text">{itemToUse.createdDate}</span>
                </FormItem>
                <FormItem {...FormItemLayout4AuditChangeTitle} label={fmtMsg({ id: SchoolLocale.CIMSChangeEventModifyby })}>
                    <span className="ant-form-text">{itemToUse.createdBy}</span>
                </FormItem>
            </div>
        </React.Fragment>) : null
    )
}

const EventModalHeader: React.FC<EventModalContentProps<ChangeEventModel>> = (props: EventModalContentProps<ChangeEventModel>) => {
    const { target, subTitle, type } = props;
    const subTitleLabel = getSubTitle(target, type);
    return (
        <div className='change-event-modal-header'>
            <FormItemsLayout colTotal={1} >
                {subTitleLabel && <FormItem {...FormItemLayout4ClassName} label={subTitleLabel}>
                    <span className="ant-form-text">{subTitle}</span>
                </FormItem>}
            </FormItemsLayout>
        </div>
    )
}

