import { Action, MainTitle } from "@app/components";
import { CellType } from "@app/components/gl-grid";
import { ColumnLink, WijmoGrid } from "@app/components/grid";
import { GridRef, IWijmoSort } from "@app/components/grid/grid";
import { PathConfig } from "@app/config/pathconfig";
import { useActionCreator, useSelector } from "@app/hooks";
import { useEffectAfterFirst } from "@app/hooks/useEffectAfterFirst";
import { SchoolLocale } from "@app/locales/localeid";
import { ChangeEventModel, ChangeLogModel, ChangeLogPropsModel, EventModalContentProps } from "@app/service/cims";
import { CIMSState, getChangeEventData as getChangeEventDataAction, getChangeLogs as getChangeLogsAction } from "@app/states/cims";
import { ChangeLogType, CONSTS, DateHelper, escapeHtml, EventInfo, EventStatus, fmtMsg, getCIMSEvent, GSSchoolAction, regExpEscape } from "@app/util";
import { Col, Row, Switch } from "antd-min";
import { GLGlobal, GLLocale, GLUtil } from "gl-commonui";
import { isNaN } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { FlexGrid, FormatItemEventArgs, GroupRow } from "wijmo/wijmo.grid";
import { ResourceType2ChangeLogType } from "./cims-consts";
import { EventModal } from "./event-modal";

const { Grid, Column } = WijmoGrid;

export interface ChangeLogProps extends Partial<RouteComponentProps<any>> {
    searchPlaceHolderId?: string;
    search4Class?: boolean;
    logTypes: ChangeLogType[];
    isCampus?: boolean;
    approveDenyPending: (query) => void;
    resetChangeFieldData: (query) => void;
    setRefreshDataFlag: (query) => void;
    getPendingChangesDetail: (query) => void;
    reload: (state: CIMSState) => void;
}

const columnSortingMap: Map<string, string> = new Map<string, string>([[ChangeLogPropsModel.type4Display, ChangeLogPropsModel.type]]);

export const ChangeLog: React.FC<ChangeLogProps> = (props: ChangeLogProps) => {
    const [keyword, setKeyword] = useState("");
    const [groupEnabled, setGroupEnabled] = useState<boolean>(false);
    const [showResolvedEvent, setShowResolvedEvent] = useState<boolean>(false);
    const [changeLogTarget, setChangeLogTarget] = useState(ChangeLogType.School);
    const [subTitle, setSubTitle] = useState<string>("");
    const lastParams = useRef(null);
    const openModalItem = useRef(null);
    const [approveDenyData, setApproveDenyData] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        total: 0,
        hideOnSinglePage: true,
        pageSize: 50,
        onChange: null,
        showTotal: (total, range) =>
            fmtMsg(
                { id: GLLocale.Pagination },
                { from: range[0], to: range[1], total }
            )
    });

    const [eventModal, setEventModal] = useState(null);
    const eventModalItem = useRef(null);
    const gridRef = useRef<GridRef>(null);

    const getChangeLogs = useActionCreator(getChangeLogsAction);
    const getChangeEventData = useActionCreator(getChangeEventDataAction);

    const { changeLogs, total, changeEventData, loading4Modal, loading, changeFieldData, loading4ApproveDeny } = useSelector((state) => state.cims);

    const {
        isCampus,
        match: { params },
        history,
        searchPlaceHolderId,
        search4Class,
        logTypes,
    } = props;
    const { regionId, schoolId, campusId } = params;

    const defaultSorter: IWijmoSort = {
        column: ChangeLogPropsModel.createdDate,
        ascending: false,
    };

    useEffectAfterFirst(() => {
        gridRef.current.reload();
    }, [showResolvedEvent, groupEnabled]);

    useEffect(() => {
        setPagination((pagination) => {
            return {
                ...pagination,
                total: total,
            };
        });
    }, [total]);

    useEffect(() => {
        if(!loading4ApproveDeny) {
            getData(lastParams.current);
            onCloseEventDataModal();
        }
    }, [loading4ApproveDeny])

    const getColumnSorter = (sorter: IWijmoSort) => {
        if (!sorter) return null;
        const columnSortName = columnSortingMap.has(sorter.column) ? columnSortingMap.get(sorter.column) : sorter.column;
        if (sorter.column == ChangeLogPropsModel.campusName) {
            return {
                sortBy: [ChangeLogPropsModel.campusName, ChangeLogPropsModel.schoolClassName],
                isDescending: [!sorter.ascending, !sorter.ascending],
            };
        }
        else if (sorter.column == ChangeLogPropsModel.statusText)
        {
            return {
                sortBy: ChangeLogPropsModel.status,
                isDescending: !sorter.ascending,
            };
        }
        else {
            return {
                sortBy: columnSortName,
                isDescending: !sorter.ascending,
            };
        }
    };

    const getData = async (params?: any) => {
        // save last parameter state
        lastParams.current = params;

        const { sortBy, isDescending, offset, limit, ...rest } = params;
        const isShowResolvedEvent = params.status == null ? showResolvedEvent : params.status;
        setKeyword(params.keyword);
        getChangeLogs({
            query: {
                ...rest,
                offset: isNaN(offset) ? 0 : offset,
                limit: limit === Infinity ? undefined : limit,
                search4Class,
                logTypes,
                status: isShowResolvedEvent ? [EventStatus.Approved, EventStatus.Denied] : [EventStatus.Pending],
                ...getColumnSorter({ column: sortBy, ascending: !isDescending }),
                schoolId,
                campusId,
            },
        });
    };

    const onFilter = (item: ChangeLogModel) => {
        return (
            !groupEnabled ||
            keyword.length == 0 ||
            (item.schoolName && item.schoolName.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (item.campusName && item.campusName.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (item.schoolClassName && item.schoolClassName.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
        );
    };

    const formatItem = (grid: FlexGrid, event: FormatItemEventArgs) => {
        const row = event.panel.rows[event.row];
        if (row instanceof GroupRow && event.panel.cellType != CellType.RowHeader) {
            const result = onFormatGroupPanel(event.cell.innerHTML, row);
            if (result) {
                event.cell.innerHTML = result;
            }
        }
    };

    const onFormatGroupPanel = (groupHtml: string, groupData: GroupRow) => {
        if (!groupHtml) return null;
        const modelValue = groupData.dataItem.name;
        const htmlValue = `<b>${typeof modelValue == "string" ? escapeHtml(modelValue) : modelValue}</b>`;
        let customizedHtmlValue = null;
        const regExp = new RegExp(regExpEscape(htmlValue), "i");
        const parts = groupHtml.split(regExp);
        const countPart = parts[1] ? parts[1].replace(/\d+/g, groupData.dataItem.items.length) : "";
        switch (groupData.dataItem.groupDescription.propertyName) {
            case ChangeLogPropsModel.type:
                customizedHtmlValue = fmtMsg({
                    id: CONSTS.CIMSEventType4Display.get(modelValue),
                });
                break;
            case ChangeLogPropsModel.status:
                customizedHtmlValue = CONSTS.CIMSEventStatus4Display.get(modelValue)();
                break;
            case ChangeLogPropsModel.createdDate:
                const createDate = groupData.dataItem.items.length > 0 ? groupData.dataItem.items[0].createdDate : "";
                customizedHtmlValue = createDate ? `<b>${DateHelper.formatDateTime2Local(createDate, true, null, true)}</b>` : "";
                break;
            default:
                customizedHtmlValue = htmlValue;
                break;
        }
        return countPart ? `${parts[0]}<b>${customizedHtmlValue}</b>${countPart}` : null;
    };

    const showPopup = (changeLog: ChangeLogModel) => {
        eventModalItem.current = changeLog;
        openModalItem.current = changeLog;
        setChangeLogTarget(changeLog.target);
        setSubTitle(getSubTitle(changeLog.target, changeLog));
        if (changeLog.status !== 0) {
            // approved / denied changes, then load event data as normal
            onOpenEventDataModal(changeLog.id, changeLog.type, changeLog.target);
        } else {
            // pending changes, using logic for approve-deny-modal
            const { getPendingChangesDetail } = props;
            setEventModal(changeLog.type);

            const approveData = {
                target: changeLog.target,
                schoolId: changeLog.schoolId,
                campusId: changeLog.campusId,
                classId: changeLog.schoolClassId,
                types: [changeLog.type]
            };
            // openModalItem.current = changeLog;
            getPendingChangesDetail(approveData);
            setApproveDenyData(approveData);
        }


    };

    const getSubTitle = (target: ChangeLogType, item: ChangeLogModel) => {
        switch (target) {
            case ChangeLogType.School:
                return "";
            case ChangeLogType.Campus:
                return item.type == EventInfo.PendingChangesMaterialOrder ? item.materialRequestId : item.campusName;
            case ChangeLogType.SchoolClass:
                return item.schoolClassName;
        }
    };

    const onOpenEventDataModal = (eventId, eventType, eventTarget) => {
        const {
            match: {
                params: { schoolId },
            },
        } = props;
        setEventModal(eventType);
        getChangeEventData({ schoolId, eventId, target: eventTarget });
    };

    const onCloseEventDataModal = () => {
        setEventModal(null);
        setApproveDenyData(null);
        props.resetChangeFieldData({});
    };

    const onApproveModal = event => {
        event.preventDefault();
        onApprove();
    };

    const onDenyModal = data => {
        onApprove(false, data);
    };

    const onApprove = (
        approve: boolean = true,
        data?
    ) => {
        const { approveDenyPending } = props;
        // remove item being ack or denied from bulk selection
        const newSelectedItems = selectedItems.filter(i => {
            const itemTarget = ResourceType2ChangeLogType.get(
                i.resourceType
            );
            return !(
                i.schoolClassId === approveDenyData.classId &&
                approveDenyData.types.some(s => s === i.changeType) &&
                itemTarget === approveDenyData.target
            );
        });
        setSelectedItems(newSelectedItems);

        const eventId = getFirstChangeEventId(openModalItem.current);
        approveDenyPending({
            ...approveDenyData,
            approve,
            data,
            eventId,
            // skipping reload pending changes
            skipReload: true
        });

    };

    const getFirstChangeEventId = curItem => {
        return curItem && curItem.id;
    };

    const renderEventModal = () => {
        const { schoolId, campusId, materialOrderId, materialRequestId, schoolClassName } = eventModalItem.current;
        const item = getItemData(eventModalItem.current);

        const eventModalProps: EventModalContentProps<ChangeEventModel> = {
            ...{schoolId, campusId, materialOrderId, regionId, materialRequestId, schoolClassName},
            target: changeLogTarget,
            type: eventModal,
            visible: eventModal != null,
            loading: loading4Modal,
            subTitle,
            item,
            history: history as any,
            onCancel: onCloseEventDataModal,
            onApprove: onApproveModal,
            onDeny: onDenyModal,
            showResolvedEvent
        };
        return <EventModal {...eventModalProps} />;
    };

    const getItemData = (changeLog: ChangeLogModel) => {
        const { createdBy, createdDate, campusName } = eventModalItem.current;

        // for approved / denied changes
        if (changeLog.status !== 0) {
            return eventModal === EventInfo.PendingChangesMaterialOrder && changeLogTarget === ChangeLogType.Campus
                ? {
                      createdBy,
                      createdDate: DateHelper.toLocalTimeStringFromUTC(
                        createdDate
                    ),
                      campusName
                  }
                : changeEventData;
        }

        // for pending changes
        return eventModal === EventInfo.PendingChangesMaterialOrder && approveDenyData.target === ChangeLogType.Campus
        ? [
            {
                createdBy,
                createdDate: DateHelper.toLocalTimeStringFromUTC(
                    createdDate
                ),
                campusName
            }
        ]
        : changeFieldData;
    }

    const getStatusText = (status) => {
        switch(status) {
            case EventStatus.Pending:
                return fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusPending });
            case EventStatus.Approved:
                return fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusAcknowledged });
            case EventStatus.Denied:
                return fmtMsg({ id: SchoolLocale.CIMSSchoolLogEventStatusReverted });
            default:
                return "";
        }
    }

    const formatData = (data: ChangeLogModel[]) => {
        return data.map((item) => {
            let msg = "";
            if (item.target === ChangeLogType.Campus && item.type === EventInfo.PendingChangesMaterialOrder) {
                msg = fmtMsg(
                    {
                        id: getCIMSEvent(item.target).get(item.type as never),
                    },
                    {
                        materialRequestId: item.materialRequestId,
                    },
                );
            } else {
                msg = fmtMsg({
                    id: getCIMSEvent(item.target).get(item.type as never),
                });
            }
            return {
                ...item,
                type4Display: msg,
                statusText: getStatusText(item.status),
            }
        })
    }

    const renderContent = () => {
        const placeholder = searchPlaceHolderId ? fmtMsg({ id: searchPlaceHolderId }) : fmtMsg({ id: SchoolLocale.CIMSSchoolLogSearchPlaceholder });
        return (
            <div className="content-layout cims change-log">
                <MainTitle title={SchoolLocale.CIMSSchoolLogTitle} />
                <div className="page-content">
                    <Grid
                        serviceFunction={getData as any}
                        itemsSource={formatData(changeLogs)}
                        search={true}
                        grouping={groupEnabled}
                        paginationSize={groupEnabled ? Infinity : pagination.pageSize}
                        paginationTotal={pagination.total}
                        paginationShowTotal={(total, range) => fmtMsg({ id: GLLocale.Pagination }, { from: range[0], to: range[1], total })}
                        sortInClient={groupEnabled}
                        defaultSort={defaultSorter}
                        ref={gridRef}
                        searchOptions={{ label: placeholder }}
                        formatItem={formatItem}
                        filter={onFilter}
                        actions={[
                            !groupEnabled && (
                                <Action
                                    key="1"
                                    onClick={() => setGroupEnabled(true)}
                                    textLocaleId={SchoolLocale.CIMSPendingChangesEnableGroupSwitch}
                                />
                            ),
                            <Action key="2">
                                <Row type="flex" gutter={5}>
                                    <Col>
                                        <Switch onChange={setShowResolvedEvent} />
                                    </Col>
                                    <Col>
                                        {fmtMsg({
                                            id: SchoolLocale.CIMSSchoolLogDenySwitch,
                                        })}
                                    </Col>
                                </Row>
                            </Action>,
                        ]}
                    >
                        <Column
                            binding={ChangeLogPropsModel.type4Display}
                            header={fmtMsg({
                                id: SchoolLocale.CIMSSchoolLogEventType,
                            })}
                            render={(value, item) => {
                                return <a onClick={() => showPopup(item)}>{value}</a>;
                            }}
                        />
                        {!isCampus && (
                            <Column
                                binding={ChangeLogPropsModel.campusName}
                                header={fmtMsg({
                                    id: SchoolLocale.CIMSSchoolLogCampus,
                                })}
                                render={(value, item) => {
                                    if (item.target == ChangeLogType.School) return null;
                                    if (GLGlobal.isActionValid(GSSchoolAction.Campuses)) {
                                        return (
                                            <ColumnLink
                                                url={GLUtil.pathStringify(PathConfig.Classes, { regionId, schoolId, campusId: item.campusId })}
                                            >
                                                {value}
                                            </ColumnLink>
                                        );
                                    } else {
                                        return value;
                                    }
                                }}
                            />
                        )}
                        <Column
                            binding={ChangeLogPropsModel.schoolClassName}
                            header={fmtMsg({
                                id: SchoolLocale.CIMSSchoolLogClass,
                            })}
                            render={(value, item) => {
                                if (item.target != ChangeLogType.SchoolClass) {
                                    return null;
                                }
                                return (
                                    <ColumnLink
                                        url={GLUtil.pathStringify(PathConfig.Students, {
                                            regionId,
                                            schoolId,
                                            campusId: item.campusId,
                                            classId: item.schoolClassId,
                                        })}
                                    >
                                        {value}
                                    </ColumnLink>
                                );
                            }}
                        />
                        <Column
                            binding={ChangeLogPropsModel.statusText}
                            header={fmtMsg({
                                id: SchoolLocale.CIMSSchoolLogEventStatus,
                            })}
                            align="left"
                            render={(value, item) => (
                                <span dangerouslySetInnerHTML={{ __html: CONSTS.CIMSEventStatus4Display.get(item.status)() }}></span>
                            )}
                        />
                        <Column
                            binding={ChangeLogPropsModel.createdBy}
                            header={fmtMsg({
                                id: SchoolLocale.CIMSSchoolLogUpdateBy,
                            })}
                        />
                        <Column
                            binding={ChangeLogPropsModel.createdDate}
                            header={fmtMsg({
                                id: SchoolLocale.CIMSSchoolLogUpdateDate,
                            })}
                            render={(value, item) => DateHelper.formatDateTime2Local(item.createdDate, true, null, true)}
                        />
                    </Grid>
                </div>
                {eventModal != null && renderEventModal()}
            </div>
        );
    };

    return renderContent();
};
